import { Component, OnInit } from '@angular/core';
import {Registro} from '../models/user';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {UserService} from '../service/user.service';
import { IfStmt } from '@angular/compiler';
import Swal from 'sweetalert2';
import { DetalleService } from '../service/detalle.service';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {
   public registro= new Registro();

   colombia = 0;
   fya = 0;
   piloto = 0;
   sek = 0;
   techint = 0;
   proed = 0;
   
  public estados;
  public paises;
  private token = localStorage.getItem('token');
  
  campania: {campanias: string};

  constructor(private userService:UserService, private detalleService: DetalleService, private router:Router, private activatedRoute: ActivatedRoute) {
    this.detalleService.catPais(this.token).subscribe((response:any)=>{
      this.paises=response;
    });
    this.detalleService.catState(this.token).subscribe((response:any)=>{
      this.estados=response;
    });
   }

  ngOnInit() {
    this.campania = {
      campanias: this.activatedRoute.snapshot.params.campanias,
    };
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.campania.campanias = params.campanias;
        if(this.campania.campanias== 'colombia'){
          this.colombia = 1;
        }
        else if(this.campania.campanias== 'FIFYA'){
          this.fya = 1;
        }
        else if(this.campania.campanias== 'piloto'){
          this.piloto = 1;
        }
        else if(this.campania.campanias== 'SEK'){
          this.sek = 1;
        }
        else if (this.campania.campanias== 'techint') {
          this.techint = 1;
        }
        else if (this.campania.campanias== 'proed') {
          this.proed = 1;
        }
      }
    );
  }

  crearUser(form:any){
    if(form.valid){
      this.registro = form.value;
      this.registro.colombia = this.colombia;
      this.registro.fya = this.fya;
      this.registro.datacole = 0;
      this.registro.piloto = this.piloto;
      this.registro.sek = this.sek;
      this.registro.techint = this.techint;
      this.registro.proed = this.proed;
      if (this.colombia == 1) {
        this.registro.txt_source = "Colombia";
      }
      else if (this.fya == 1) {
        this.registro.txt_source = "FIFYA";
        form.value.state_id = '33';
        if (form.value.pais_id == "" || form.value.pais_id == undefined) {
          Swal.fire({
            type: 'error',
            title: 'Verifica el país elegido',
            showConfirmButton: false,
            timer: 1500
          });
          return true;
        }
      }
      else if (this.piloto == 1) {
        this.registro.txt_source = "piloto";
      }
      else if (this.sek == 1) {
        this.registro.txt_source = "SEK";
      }
      else if (this.techint == 1) {
        this.registro.txt_source = "techint";
      }
      else if(this.proed==1){
        this.registro.txt_source = "proed";
      }
      this.registro.activar_licencia = (this.registro.codigo != "") ? 1 : 0;
      if ((this.registro.email == this.registro.emailConfir) && this.registro.email != ""
        && this.registro.email.match(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/)) {
        if (this.registro.password != "" && (this.registro.password == this.registro.password_conf) && this.registro.password.length >= 6) {
          if (this.registro.aviso) {
            this.userService.registro(this.registro).subscribe((response:any)=>{
              console.log(response);
              localStorage.setItem('token',response.token);
              localStorage.setItem('email', this.registro.email);

              this.router.navigate(['inicio']);

            },error=>{
              let msj = "Usuario ya existente";
              console.log(error);
              if(error["error"]["message"]=="El código es incorrecto."){
                msj = "El código de acceso es incorrecto."
              }
              Swal.fire({
                type: 'error',
                title: msj,
                showConfirmButton: false,
                timer: 1500
              });
            });
          } else {
            Swal.fire({
              type: 'error',
              title: 'Acepta nuestro aviso de privacidad',
              showConfirmButton: false,
              timer: 1500
            });
          }
        } else {
          Swal.fire({
            type: 'error',
            title: 'Verifica tu contraseña ',
            showConfirmButton: false,
            timer: 1500
          });
        }

      } else {
        Swal.fire({
          type: 'error',
          title: 'Verifica tu correo ',
          showConfirmButton: false,
          timer: 1500
        });
      }
    }else{
      Swal.fire({
        type: 'error',
        title: 'Verifica que todos los campos esten llenos.',
        showConfirmButton: false,
        timer: 1500
      });
    }
 }

}
