import { Component, OnInit } from '@angular/core';
import { Navegacion } from '../models/navigate';
import { DetalleService } from '../service/detalle.service';
import { Router } from '@angular/router';
import InfoPlanesLT from 'src/assets/JSON/descripcion.json';
declare var $: any;

@Component({
  selector: 'app-plan-predise',
  templateUrl: './plan-predise.component.html',
  styleUrls: ['./plan-predise.component.css']
})
export class PlanPrediseComponent implements OnInit {

  public planesLT = [];
  public infoplanesLT;
  private token = localStorage.getItem('token');
  public navegacion = new Navegacion();
  constructor(private detalleService: DetalleService, private router: Router) {
    this.infoplanesLT = InfoPlanesLT.info;
    this.navegacion.seccion =10;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();
    localStorage.removeItem('PlanId');
    localStorage.removeItem('PlanSlug');
    this.detalleService.mostrarPlanes(this.token).subscribe((response: any) => {

      this.planesLT = response.planesLT;
    });
  }

  ngOnInit() {
    $(".modal .close").click(function () {
      $('.modal').fadeOut();
      $('.modal').removeClass('show');
      console.log('close modal');
    });
  }

}
