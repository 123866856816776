import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Password } from '../models/perfil';
import { Registro, User } from '../models/user';
import { UserService } from '../service/user.service';
declare var $:any;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  public user: User;
  public newPassword = new Password();
  refresh: string = "false";
  mensaje?= "";
  isCorrect: boolean = true;

  public registro= new Registro();

  constructor(private userService: UserService,  private activatedRoute: ActivatedRoute, private router: Router) {
    localStorage.clear();
    this.user = new User(1, '', '');
  }

  ngOnInit() {
  }

  onSubmit(form: any) {
    this.user.datacole = 1;
    this.userService.login(this.user).subscribe((response: any) => {

      if (localStorage.getItem('token')) {
        localStorage.clear();
        this.router.navigate(['datacole']);
      }
      localStorage.setItem('token', response.token);
      console.log(response);
      let fechaLogin = new Date();
      let fechaAux = fechaLogin.toString();
      localStorage.setItem('fechaLogin', fechaAux);
      localStorage.setItem('email', this.user.email);
      this.router.navigate(['inicio']);
    }, error => {
      Swal.fire({
        type: 'error',
        title: 'Datos incorrectos',
        showConfirmButton: false,
        timer: 1500
      });
      this.router.navigate(['datacole']);
      if (error.statusText === "Unauthorized") {
        this.mensaje = "Datos incorrectos";
        this.isCorrect = false;
        this.user.email = "";
        this.user.password = "";
      }
    });
  }

  recuperar(form:any){
    this.userService.recuperarContraseña(this.newPassword).subscribe((response:any)=>{
      console.log(response);
      localStorage.clear();
      this.newPassword.email='';
      this.newPassword.password='';
      
      $('#modalEditar').modal('hide');
    
      Swal.fire({
        type: 'success',
        title: 'Contraseña actualizada.',
        showConfirmButton: false,
        timer: 1500
      });
    });
  }

  crearUser(form:any){
    console.log(form.value); 
    this.registro=form.value;
    
    $('#modalRegistro').modal('hide');

    this.registro.colombia = 0;
    this.registro.fya = 0;
    this.registro.datacole = 1;
    this.registro.txt_source = "datacole";
    console.log('registro',this.registro);
    if ((this.registro.email == this.registro.emailConfir) && this.registro.email!=""){
      if(this.registro.password!="" && (this.registro.password == this.registro.password_conf)){
        if(this.registro.aviso){
          console.log(this.registro)
          this.userService.registro(this.registro).subscribe((response:any)=>{
            console.log(response);
            localStorage.setItem('token',response.token);
            localStorage.setItem('email', this.registro.email);
            this.router.navigate(['inicio']);
          },error=>{
            let msj = "Usuario ya existente";
            console.log(error);
            Swal.fire({
              type: 'error',
              title: msj,
              showConfirmButton: false,
              timer: 1500
            });
          });
        } else {
          Swal.fire({
            type: 'error',
            title: 'Acepta nuestro aviso de privacidad',
            showConfirmButton: false,
            timer: 1500
          });
        }
      } else {
        Swal.fire({
          type: 'error',
          title: 'Verifica tu contraseña ',
          showConfirmButton: false,
          timer: 1500
        });
      }
  
    } else {
      Swal.fire({
        type: 'error',
        title: 'Verifica tu correo ',
        showConfirmButton: false,
        timer: 1500
      });
    }
    
    
   }

}
