import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Navegacion } from '../models/navigate';
import { DetalleService } from '../service/detalle.service';
declare var $: any;

@Component({
  selector: 'app-plan-sesion',
  templateUrl: './plan-sesion.component.html',
  styleUrls: ['./plan-sesion.component.css']
})
export class PlanSesionComponent implements OnInit {

  public grado: boolean = false;
  private token = localStorage.getItem('token');
  public navegacion = new Navegacion();
  public planeaciones = [];
  public materias = [];
  public baja = [];
  public media = [];
  public alta = [];

  constructor(private detalleService: DetalleService, private router: Router) {
    this.navegacion.seccion =10;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();

    this.detalleService.getPlaneacionesxSesion(this.token).subscribe((response: any) => {
      console.log(response);
      this.materias = response.materias;
      this.planeaciones = response.planeaciones;
    });
  }

  ngOnInit() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    $(".modal .close").click(function () {
      $('.modal').fadeOut();
      $('.modal').removeClass('show');
      console.log('close modal');
    });
  }

  handleMostrar:any = (periodo) => {
    if(periodo==1) {this.grado=false;}
    else if(periodo==0) {this.grado=true; this.nivel();}
  }

  nivel(){
    this.planeaciones.forEach(p => {
      if(p.nivel.includes('baja')){
        this.baja.push(p);
      }
      if(p.nivel.includes('media')){
        this.media.push(p);
      }
      if(p.nivel.includes('alta')){
        this.alta.push(p);
      }
      console.log(this.baja);
      console.log(this.media);
      console.log(this.alta);
    });
  }

}
