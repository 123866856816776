export class  Perfil{
    public user_id= " ";
    public email= " ";
    public genero= "";
    public pais_id= " ";
    public state_id= " ";
    public date_register= " ";
    public terms= " ";
    public edad= " ";
    public username= " ";
    public password= " ";
    public name= "";
    public lastname= "";
    public area_id= " ";
    public asignaturas= "";
    public player_id= " ";
    public sha_username= " ";
    public status= " ";
    public is_deleted= " ";
    public is_full_record= " ";
    public is_email_ok= " ";
    public user_rol_id= " ";
    public source_id= " ";
    public cct_id= " ";
    public cct_text= "";
    public cct= " ";
    public has_license=  " ";
    public img = " ";
  }
export class Foto{
  picture = " " ;
  user_id;
}

export class Password{
  public email = '';
  public password = '';
}
