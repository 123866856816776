export class User {
    constructor(
        public is_email: number,
        public email: string,
        public password: string,
        public colombia = 0,
        public fya = 0,
        public datacole = 0,
        public piloto = 0,
        public sek = 0,
        public techint = 0,
        public proed = 0,
        public activar_licencia = 0,
        public codigo = ""
    ) { }
}
export class Registro {
    public email = "";
    public emailConfir = "";
    public name = "";
    public lastname = "";
    public password = "";
    public password_conf = "";
    public genero = "";
    public area_id = "";
    public pais_id = "";
    public terms = "";
    public type_form = " labtak";
    public user_rol_id = "";
    public txt_source = "";
    public captcha = "";
    public state_id = "";
    public invt_username = "";
    public phone = "";
    public aviso = "";
    public colombia = 0;
    public fya = 0;
    public codigofya = "";
    public datacole = 0;
    public piloto = 0;
    public sek = 0;
    public techint = 0;
    public proed = 0;
    public activar_licencia = 0;
    public codigo = "";
    public codigoproed = "";
}