export var config = {
  //url: 'http://api.local.labtak:88/v1/'
  //url: 'https://api.takflics.com/v1/'
  //url: 'https://api.fundacioninoma.mx/v1/'
  url: 'https://api.dev.labtak.mx/v1/' 
  //url: 'http://localhost:4000/v1/' //pruebas nodejs 
}

export var imagenes = {
  url: 'https://taktaktak.com/assets/files/games/images/'
}

export var imagenesBlog = { //Pruebas Blog
  //url: 'http://localhost:4000/static/blog/'
  url: 'http://api.dev.labtak.mx/static/blog/' 
}

export var imagenesCapacitaciones = { //Pruebas capacitaciones
  //url: 'http://localhost:4000/static/capacitaciones/'
  url: 'http://api.dev.labtak.mx/static/capacitaciones/' 
}

export var videosCapacitaciones = { //Pruebas webinars
  //url: 'http://localhost:4000/static/capacitaciones/video-webinars/'
  url: 'http://api.dev.labtak.mx/static/capacitaciones/video-webinars/' 
}
export var materiales = { //Pruebas webinars
  //url: 'http://localhost:4000/static/capacitaciones/materiales_moocs/'
  url: 'http://api.dev.labtak.mx/static/capacitaciones/materiales_moocs/' 
}

export var certificados = {
  //url: 'https://api.dev.labtak.mx/static/certificados/' 
  url: 'http://localhost:4000/static/certificados/' 
}