import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { PerfilComponent } from './perfil/perfil.component';
import { GruposComponent } from './grupos/grupos.component';
import { ModalComponent } from './modal/modal.component';
import { ListadoComponent } from './listado/listado.component';
import { PlanesComponent } from './planes/planes.component';
import { NuevoPlanComponent } from './nuevo-plan/nuevo-plan.component';
import { PlanDetalleComponent } from './plan-detalle/plan-detalle.component';
import { ItemContenidoComponent } from './item-contenido/item-contenido.component';
import { ItemJuegoComponent } from './item-juego/item-juego.component';
import { LoginComponent } from './login/login.component';
import { EditarGrupoComponent } from './editar-grupo/editar-grupo.component';
import { ReportakComponent } from './reportak/reportak.component';
import { ReportakVideojuegosComponent } from './reportak-videojuegos/reportak-videojuegos.component';
import { ReportakGrupoComponent } from './reportak-grupo/reportak-grupo.component';
import { RegistroComponent } from './registro/registro.component';
import { Header2Component } from './header2/header2.component';
import { InicioComponent } from './inicio/inicio.component';
import { ModalBuscarComponent } from './modal-buscar/modal-buscar.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VerJuegoComponent } from './ver-juego/ver-juego.component';
import { NgDragDropModule } from 'ng-drag-drop';
import { SafePipe } from './pipe/safe.pipe';
import { SanitizeHtmlPipe } from './pipe/sanitizeHTML.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatInputModule, MatPaginatorModule, MatProgressSpinnerModule, 
  MatSortModule, MatTableModule, MatCheckboxModule } from "@angular/material";
import {NgxPaginationModule} from 'ngx-pagination';
import { NewHomeComponent } from './new-home/new-home.component';
import { PlanPrediseComponent } from './plan-predise/plan-predise.component';
import { PlanpreDetalleComponent } from './planpre-detalle/planpre-detalle.component';
import { ActividadesComponent } from './actividades/actividades.component';
import { ActividadesDetalleComponent } from './actividades-detalle/actividades-detalle.component';
import { ModalAddplanComponent } from './modal-addplan/modal-addplan.component';
import { LandingComponent } from './landing/landing.component';
import { PlanSesionComponent } from './plan-sesion/plan-sesion.component';

import {AuthGuardService} from './service/guard/auth-guard.service';

import { ArticuloComponent } from './articulo/articulo.component';
import { BlogComponent } from './blog/blog.component';
import { ForoComponent } from './foro/foro.component';
import { ForoEntradaComponent } from './foro-entrada/foro-entrada.component';
import { ModalCompartirComponent } from './modal-compartir/modal-compartir.component';
import { PlanPublicasComponent } from './plan-publicas/plan-publicas.component';
import { PlanPublicasDetalleComponent } from './plan-publicas-detalle/plan-publicas-detalle.component';
import { PlanCompartidaComponent } from './plan-compartida/plan-compartida.component';
import { ActividadesPublicasComponent } from './actividades-publicas/actividades-publicas.component';
import { TiendaComponent } from './tienda/tienda.component';
import { ModalSesionesComponent } from './modal-sesiones/modal-sesiones.component';
import { ReglasBlogyForoComponent } from './reglas-blogy-foro/reglas-blogy-foro.component';
import { RecibosPagosComponent } from './recibos-pagos/recibos-pagos.component';

import { UserIdleModule } from 'angular-user-idle'; //medidor de inactividad del usuario
import { CapacitacionComponent } from './capacitacion/capacitacion.component';
import { CapacitacionListadoComponent } from './capacitacion-listado/capacitacion-listado.component';
import { CapacitacionWebinarComponent } from './capacitacion-webinar/capacitacion-webinar.component';
import { CapacitacionMoocComponent } from './capacitacion-mooc/capacitacion-mooc.component';
import { CapacitacionWebinarLiveComponent } from './capacitacion-webinar-live/capacitacion-webinar-live.component';
import { ZoomComponent } from './zoom/zoom.component';
import { MoocComponent } from './mooc/mooc.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    PerfilComponent,
    GruposComponent,
    ModalComponent,
    ListadoComponent,
    PlanesComponent,
    NuevoPlanComponent,
    PlanDetalleComponent,
    ItemContenidoComponent,
    ItemJuegoComponent,
    LoginComponent,
    EditarGrupoComponent,
    ReportakComponent,
    ReportakVideojuegosComponent,
    ReportakGrupoComponent,
    RegistroComponent,
    Header2Component,
    InicioComponent,
    ModalBuscarComponent,
    VerJuegoComponent,
    SafePipe,
    SanitizeHtmlPipe,
    NewHomeComponent,
    PlanPrediseComponent,
    PlanpreDetalleComponent,
    ActividadesComponent,
    ActividadesDetalleComponent,
    ModalAddplanComponent,
    LandingComponent,
    PlanSesionComponent,
    ArticuloComponent,
    BlogComponent,
    ForoComponent,
    ForoEntradaComponent,
    ModalCompartirComponent,
    PlanPublicasComponent,
    PlanPublicasDetalleComponent,
    PlanCompartidaComponent,
    ActividadesPublicasComponent,
    TiendaComponent,
    ModalSesionesComponent,
    ReglasBlogyForoComponent,
    RecibosPagosComponent,
    CapacitacionComponent,
    CapacitacionListadoComponent,
    CapacitacionWebinarComponent,
    CapacitacionMoocComponent,
    CapacitacionWebinarLiveComponent,
    ZoomComponent,
    MoocComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    NgDragDropModule.forRoot(),
    BrowserAnimationsModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    NgxPaginationModule,
    AngularEditorModule,
    UserIdleModule.forRoot({idle: 900, timeout: 1, ping: 10})
  ],
  entryComponents:[
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
