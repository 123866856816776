import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    setTimeout(function () {
      $(".js-video-play").click(function (e) {
        e.preventDefault();
        $('#video-item').addClass("active");
        $('#video-item').get(0).play();
        $(this).fadeOut();
      });
    }, 1500);

    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  
  }

}
