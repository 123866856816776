
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GameDescription, GamePrograma, GameRecurso, GameAbility, GameRuta } from '../models/game';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { config } from './config';

@Injectable({
  providedIn: 'root'
})
export class DetalleService {
  public url = config.url;
  
  constructor(public http: HttpClient) { }

  verDetalle(token: string, slug: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    return this.http.get(this.url + `ts/game/${slug}`, { headers: headers })
      .pipe(map(data => {
        return data['ficha'];
      })
      );
  }

  verDetalleRecurso(token: string, id: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `ts/recursos-externos/${id}`;
    return this.http.get(url, { headers: headers });
  }
  obtenerPDF(token: string, id_ruta: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `ts/getPDF/${id_ruta}`;
    return this.http.get(url, { headers: headers });
  }
  detallePerfil(token: string ) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `user/perfil/ `;
    return this.http.get(url, { headers: headers });
  }
  updatePerfil(token: string,data) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `perfil/actualiza-perfil/ `;
    return this.http.post(url,data, { headers: headers });
  }
  mostrarPlanes(token: string  ) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `lt-users/planeador `;
    return this.http.get(url,  { headers: headers });
  }
  detallePlanes(token: string,userId,slug:string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `lt-users/planeador/${userId}/${slug} `;
    return this.http.get(url, { headers: headers });
  }
  addPlanesLT(token: string,data){
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `lt-users/planeador `;
    return this.http.post(url, data,  { headers: headers });
  }
  eliminarPlan(token: string, id) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `lt-users/planeador/${id} `;
    return this.http.delete(url, { headers: headers });
  }
  actualizarPlanLTeliminado(token: string, data) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `lt-users/planeador/`;
    return this.http.post(url, data,{ headers: headers });
  }
  listarALumnos(token: string, access) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `lt-users/alumnos/${access}`;
    return this.http.get(url,  { headers: headers });
  }
  planesofGrupo(token: string, access) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `lt-users/grupos/${access}`;
    return this.http.get(url, { headers: headers });
  }
  addStudentToGroup(token: string, data) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `lt-users/alumnos/`;
    return this.http.post(url,data, { headers: headers });
  }
  desvicnularStudent(token,access,id){
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `lt-users/alumnos/${access}/${id}`;
    return this.http.delete(url, { headers: headers });
 
  }
  grupos(token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    let url = this.url + `lt-users/grupos/`;
    // Petición
    return this.http.get(url, { headers: headers });
  }
  vincularGrupo(token, plan, grupo) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `lt-users/plan-to-group/${plan}/${grupo}`;
    return this.http.post(url,{}, { headers: headers });

  }
  deleteGrupo(token, plan, grupo) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `lt-users/plan-to-group/${plan}/${grupo}`;
    return this.http.delete(url, { headers: headers });

  }
  planesInGroup(token: string,id) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    let url = this.url + `lt-users/plan-to-group/${id}`;
    // Petición
    return this.http.get(url, { headers: headers });
  }

  deleteGame(token, plan, game) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `lt-users/game-to-plan/${plan}/${game}`;
    return this.http.delete(url, { headers: headers });

  }
  deleteRecursosExternos(token, plan, recursoExterno) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `lt-users/recurso-to-plan/${plan}/${recursoExterno}`;
    return this.http.delete(url, { headers: headers });

  }
  deleteLibro(token, plan, libro) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `lt-users/libro-to-plan/${plan}/${libro}`;
    return this.http.delete(url, { headers: headers });

  }
  deleteHabilidad(token, plan, habilidad) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `lt-users/habilidades-to-plan/${plan}/${habilidad}`;
    return this.http.delete(url, { headers: headers });

  }
  deleteDescripciones(token, plan, descripcion) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `lt-users/descripciones-to-plan/${plan}/${descripcion}`;
    return this.http.delete(url, { headers: headers });

  }
  deleteOtrosrecursos(token, plan, otrorecurso) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `lt-users/otros-recursos-to-plan/${plan}/${otrorecurso}`;
    return this.http.delete(url, { headers: headers });

  }
  deleteRuta(token, plan, ruta) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `lt-users/ruta-to-plan/${plan}/${ruta}`;
    return this.http.delete(url, { headers: headers });

  }
  deletePrograma(token, plan, programa) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `lt-users/programas-academicos-to-plan/${plan}/${programa}`;
    return this.http.delete(url, { headers: headers });

  }
  editarNamePlan(token,data, plan) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `lt-users/planeador/${plan}`;
    return this.http.post(url,data, { headers: headers });
  }
  editarNuevoPlan(token,data, plan) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `lt-users/nuevoplaneador/${plan}`;
    return this.http.post(url,data, { headers: headers });
  }
  
  editarJuegoRecSesiones(token,data, plan) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `lt-users/nuevoplaneador/${plan}`;
    return this.http.post(url,data, { headers: headers });
  }

  editarFotoPerfil(token, data) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `perfil/update-picture`;
    return this.http.post(url, data, { headers: headers });
  }
  navegacion(token, data) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `navegacion`;
    return this.http.post(url, data, { headers: headers });
  }
  navegacionJuego(token, data) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `navegacionGames`;
    return this.http.post(url, data, { headers: headers });
  }
  navegacionRecurso(token, data) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `navegacionRecursosExternos`;
    return this.http.post(url, data, { headers: headers });
  }
  navegacionBlog(token, data) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `navegacion/blog`;
    return this.http.post(url, data, { headers: headers });
  }
  navegacionBlogOther(token, data) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `navegacion/blog-other`;
    return this.http.post(url, data, { headers: headers });
  }
  navegacionForo(token, data) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `navegacion/foro`;
    return this.http.post(url, data, { headers: headers });
  }
  catPais(token) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `cat/paises`;
    return this.http.get(url, { headers: headers });
  }
  catState(token) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `cat/estados`;
    return this.http.get(url,  { headers: headers });
  }
  catCCT(token) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `cat/cct`;
    return this.http.get(url, { headers: headers });
  }
  catMunicipio(token, estado) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `cat/municipios/` + estado;
    return this.http.get(url,  { headers: headers });
  }
  catCCTState(token, datos) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `cat/cct-state`;
    return this.http.post(url, datos, { headers: headers });
  }
  addCCT(token, datos) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `adquisicion/cct-user`;
    return this.http.post(url, datos, { headers: headers });
  }
  deleteCCT(token, datos) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `cat/cct-delete`;
    return this.http.post(url, datos, { headers: headers });
  }
  getTags(){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    let url = this.url + `ts/tags`;
    return this.http.get(url, { headers: headers });
  }
  
  getModedu(token) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `ts/modeloeducativo`;
    return this.http.get(url, { headers: headers });
  }

  getModeduPlan(token) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `planeaciones/modeloeducativo-plan`;
    return this.http.get(url, { headers: headers });
  }

  getMateriaGrado(token) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `ts/gradomateria`;
    return this.http.get(url, { headers: headers });
  }

  getReformaxGrado(token, grado, asignatura){
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `ts/reformagr/${grado}/${asignatura}`;
    return this.http.get(url, { headers: headers });
  }

  getPlaneacionesxSesion(token){
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `lt-users/planeaciones-sesion`;
    return this.http.get(url, { headers: headers });
  }

  postVinculacionWithExcel(fd:any){
    let headers: HttpHeaders = new HttpHeaders();
    return this.http.post(this.url + 'lt-users/vinculacion-excel', fd, { headers: headers });
  }

  notificaciones(token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    return this.http.get(this.url + `perfil/notificaciones-perfil `, { headers: headers });
  }
} 
