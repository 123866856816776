import { Component, OnInit } from '@angular/core';
import { BlogService } from '../service/blog.service';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { imagenesBlog } from '../service/config'; 
import { DetalleService } from '../service/detalle.service';
import { Navegacion } from '../models/navigate';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  private resBlog: any = [];
  private respaldo: any =[];
  private tagsSearch:string;
  private resSearch: any = [];
  private ResulNull:boolean = false;

  private urlIMG = imagenesBlog.url;

  private token: string = localStorage.getItem('token');
  public navegacion = new Navegacion();

  constructor(private detalleService: DetalleService, private blogService: BlogService) {
    this.navegacion.seccion = 26;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();
  }

  busqueda(){
    if(this.tagsSearch==""){
      this.resBlog.blog = this.respaldo;
      this.ResulNull = false;
    }
    else{
      this.blogService.getSearch(this.tagsSearch).subscribe((response: any) => {
        this.resSearch = response;
        if(this.resSearch.blog.length == 0){
          this.ResulNull = true;
          this.resBlog.blog = this.resSearch.blog;
        }
        else{
          this.ResulNull = false;
          this.resBlog.blog = this.resSearch.blog;
        }
      });
    }
  }

  ngOnInit() {
    this.blogService.getArticulos().subscribe((response: any) => {
        this.resBlog = response;
        this.respaldo = this.resBlog.blog;
        console.log("adios",this.resBlog); 
    });
    
    let top = document.getElementById('top');
      if (top !== null) {
          top.scrollIntoView();
          top = null;
      }
    }
}
